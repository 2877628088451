import React, { useEffect } from 'react';
import img from '../img/mimi.png';
import dutyfree from '../img/dutyfree__first.png';

let DutyFreeFirstSection = (): JSX.Element => {
  let fadein = () => {
    let firstImg = document.querySelector('.dutyFree__first__img') as any;
    let currentLocation = window.scrollY;
    firstImg.classList.add('fade-in');

    if (currentLocation === 0) {
      console.log(111);
      console.log(currentLocation);

      firstImg.classList.add('fade-in');
    } else {
      firstImg.classList.remove('fade-in');
    }
  };

  window.addEventListener('load', fadein);

  useEffect(() => {
    window.scrollTo(0, 0);
    fadein();
  }, []);

  const test = () => {
    const contents = document.querySelectorAll('.fade-in');

    contents.forEach((content: any) => {
      if (!contents) {
        content.style.opacity = '0';
        content.style.transform = 'translateY(100px)';
      } else {
        content.style.opacity = '1';
        content.style.transform = 'translateY(0px)';
      }
    });
  };

  return (
    <div className='dutyFree__first'>
      <div className='dutyFree__first__contents'>
        <img src={dutyfree} alt='img' className='dutyFree__first__img'></img>
        <div className='dutyFree__first__infoBox'>
          <div className='dutyFree__first__infoTitleBox'>
            <div className='dutyFree__first__infoTitle'>
              제주국제공항 'JDC' 지정 면세점 입점유통
            </div>
            <div className='dutyFree__first__infoSubTitle'>
              JDC 내국인 면세점
              <br />
              명품 병행수입 편집샵 BUTTI 매장 운영
            </div>
          </div>
          <div className='dutyFree__first__infoDescBox'>
            <div className='dutyFree__first__infoLeftDesc'>
              <div className='dutyFree__first__infoLeftDesc_title'>
                DUTYFREE DEPARTMENT.
              </div>
              <div className='dutyFree__first__infoLeftDesc_desc'>
                국토부 산하 공기업인 JDC 지정 면세점에 상품을 납품하고 있으며,
                <br />
                병행수입 업계최초로 공항면세점에 입점했습니다.
                <br />
                부띠매장은 세계 유명브랜드인 프라다, 구찌, 페라가모, 잡화 유통
                판매중 입니다.
              </div>
            </div>
            <div className='dutyFree__first__infoRightDesc'>
              <dl>
                <dt>대표명 :</dt>
                <dd>BUTTIMALL</dd>
              </dl>

              <dl>
                <dt>브랜드 : </dt>
                <dd>Prada,Gucci,Ferragamo</dd>
              </dl>

              <dl>
                <dt>입점시기 : </dt>
                <dd>2019년</dd>
              </dl>

              <dl>
                <dt>홈페이지 :</dt>
                <dd>
                  <a href='http://www.jdcdutyfree.com' target='blank'>
                    www.jdcdutyfree.com
                  </a>
                </dd>
              </dl>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DutyFreeFirstSection;
