import React, { useEffect } from 'react';
import HeadOfficeMap from './HeadOfficeMap';
import BranchOfficeMap from './BranchOfficeMap';
import headCompanyImg from '../img/headCompanyImg.png';
import branchoffice from '../img/branchOffice.png';

let ContactSecondSection = (): JSX.Element => {
  return (
    <div className='contactUs__second__contents'>
      <div className='contactUs__second__leftBox'>
        <h1>COPAMILNEW 본사</h1>
        <div className='contactUs__second__addressBox'>
          <div className='contactUs__second__addressContent'>
            <div className='contactUs__second__addressTittle'>주소</div>
            <div className='contactUs__second__addressLocation'>
              서울특별시 강남구 테헤란로 501
              <br /> 브이플랙스 패스트파이브 301호
            </div>
          </div>
          <div className='contactUs__second__addressContent'>
            <div className='contactUs__second__addressTittle'>연락처</div>
            <div className='contactUs__second__addressLocation'>
              02-6735-1007
            </div>
          </div>
        </div>
        <div className='contactUs__second__mapBox'>
          <div className='contactUs__second__mapInfoBox'>
            <div>오시는 길</div>
            <a
              href='https://map.kakao.com/link/to/코빠밀뉴본사,37.50737571214269,
              127.05683595047735'
              target='blank'
              className='contactUs__second__mapBtn'
            >
              <button>길 찾기</button>
            </a>
          </div>
          <div>
            {/* <img src={branchoffice} alt='map' className='kakaoMap'></img> */}
            <HeadOfficeMap />
          </div>
        </div>
      </div>
      <div className='contactUs__second__rightBox'>
        <h1>COPAMILNEW 지사</h1>
        <div className='contactUs__second__addressBox'>
          <div className='contactUs__second__addressContent'>
            <div className='contactUs__second__addressTittle'>주소</div>
            <div className='contactUs__second__addressLocation'>
              서울특별시 양천로 583 <br />
              우림블루나인 비즈니스센터 A동 402호
            </div>
          </div>
          <div className='contactUs__second__addressContent'>
            <div className='contactUs__second__addressTittle'>연락처</div>
            <div className='contactUs__second__addressLocation'>
              02-6735-1007
            </div>
          </div>
        </div>
        <div className='contactUs__second__mapBox'>
          <div className='contactUs__second__mapInfoBox'>
            <div>오시는 길</div>
            <a
              href='https://map.kakao.com/link/to/코빠밀뉴지사,37.55719507367589,
              126.8640728251299'
              target='blank'
              className='contactUs__second__mapBtn'
            >
              <button>길 찾기</button>
            </a>
          </div>
          <div>
            {/* <div className='kakaoMap'></div> */}
            {/* <img src={headCompanyImg} alt='map' className='kakaoMap'></img> */}
            <BranchOfficeMap />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactSecondSection;
