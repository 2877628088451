import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import '../asset/nav.scss';
import logo from '../img/logo.png';

interface SubCategory {
  list: string;
  path: string;
}

interface Category {
  id: number;
  path: string;
  title: string;
  type: string;
  children?: SubCategory[];
}

let Nav = (): JSX.Element => {
  const [isToggle, setIsToggle] = useState(false);
  const [isSubToggle, setIsSubToggle] = useState(false);

  const categories: Category[] = [
    // {
    //   path: `/aboutUs/introduction`,
    //   title: 'ABOUT US',
    //   type: 'link',
    // },
    { id: 1, path: `/`, title: 'HOME', type: 'link' },
    {
      id: 2,
      path: `/business/ecommerce`,
      title: 'BUSINESS',
      type: 'sub',
      children: [
        {
          list: 'DUTYFREE DEPARTMENT',
          path: `/business/dutyfree_department`,
        },
        {
          list: 'E-COMMERCE',
          path: `/business/ecommerce`,
        },
      ],
    },
    { id: 3, path: `/new_notice`, title: 'NEW & NOTICE', type: 'link' },
    {
      id: 4,
      path: `/contact_us`,
      title: 'CONTACT US',
      type: 'link',
    },
  ];

  let toggleHandler = () => {
    let catergory = document.querySelector('.nav__category') as any;
    let dropdown = document.querySelectorAll('.nav__category__main') as any;

    if (!isToggle) {
      // false 일떄
      // setIsToggle(!isToggle);
      setIsToggle(true);
      catergory.classList.add('toggleOn');
      setIsSubToggle(false);
      dropdown.forEach((el: any) => el.classList.remove('subToggleOn'));
    } else if (isToggle) {
      // true 일떄
      setIsToggle(false);
      setIsSubToggle(false);
      // setIsToggle(!isToggle);
      catergory.classList.remove('toggleOn');
      dropdown.forEach((el: any) => el.classList.remove('subToggleOn'));
    }
  };

  //
  //
  //
  let subToggleHandler = (e: any) => {
    let dropdown = e.target.parentElement as any;
    // let dropdown = document.querySelector('.nav__category__main') as any;
    // let dropdown = document.querySelector('.nav__category__subDropdown') as any;
    // console.log(dropdown);
    if (!isSubToggle) {
      // false 일떄
      // setIsSubToggle(!isSubToggle);
      setIsSubToggle(true);
      dropdown.classList.add('subToggleOn');
    } else {
      // true 일떄
      // setIsSubToggle(!isSubToggle);
      setIsSubToggle(false);
      dropdown.classList.remove('subToggleOn');
    }
  };

  let toggleOff = (e: any) => {
    // document.activeElement.blur();
    (document.activeElement as HTMLElement).blur();
    console.log(document.activeElement);
    let catergory = document.querySelector('.nav__category') as any;
    catergory.classList.remove('toggleOn');
    setIsToggle(false);
    setIsSubToggle(false);

    console.log('work');
    console.log(isToggle);
    console.log(isSubToggle);
  };

  // let navBar = document.querySelector('.nav__category') as any;
  // window.addEventListener('blur', () => toggleOff);

  let navBarHandler = () => {
    let navBar = document.querySelector('.nav__contentBox') as any;
    let navBarC = document.querySelector('.nav__container') as any;
    let currentY = window.scrollY;
    // console.log(navBar!.scrollHeight); // 67
    // console.log(navBar!.clientHeight); // 67
    // console.log(window.scrollY); // 67

    if (navBar.clientHeight < currentY) {
      navBarC.classList.add('active');
    } else {
      navBarC.classList.remove('active');
    }
  };

  window.addEventListener('scroll', navBarHandler);

  return (
    <div className='nav__container'>
      <div className='nav__contentBox'>
        <Link to='/' className='nav__logoBox'>
          <img src={logo} alt='Logo' className='nav__logo'></img>
        </Link>
        <ul className='nav__category'>
          {categories.map((el, i) =>
            !el.children ? (
              // no sub category

              <Link to={el.path} className='d1'>
                <li
                  className='nav__category__main'
                  onClick={toggleOff}
                  onBlur={toggleOff}
                  key={i}
                >
                  <div className='nav__category__mainList'>{el.title}</div>
                </li>
              </Link>
            ) : (
              // w sub category
              // <a href='javascrip:;' className='d1'>
              <div className='d1'>
                <li className='nav__category__main' key={i}>
                  <div
                    className='nav__category__mainList'
                    onClick={subToggleHandler}
                  >
                    {el.title}
                  </div>
                  <div className='nav__category__subDropdown'>
                    {el.children.map((child, j) => (
                      <Link to={child.path}>
                        <div
                          className='nav__category__subList'
                          onClick={toggleOff}
                          key={j}
                        >
                          {child.list}
                        </div>
                      </Link>
                    ))}
                  </div>
                </li>
              </div>
              // </a>
            )
          )}
        </ul>
        <div
          className='nav__category__hamburger'
          onClick={toggleHandler}
          onBlur={toggleOff}
        >
          <div className='nav__category__hamburgerLine'></div>
          <div className='nav__category__hamburgerLine'></div>
          <div className='nav__category__hamburgerLine'></div>
        </div>
      </div>
    </div>
  );
};

export default Nav;
