import React from 'react';
import '../asset/footer.scss';
import logo from '../img/logo.png';

let Footer = (): JSX.Element => {
  return (
    <footer className='footer__container'>
      <div className='footer__contentBox'></div>

      <div className='footer__contentBox__info'>
        <img src={logo} alt='Logo' className='footer__logo'></img>
        <p>
          {/* <div> */}
          {/* <span>상호명 코빠밀뉴 대표 : 이래관 </span>
            <span>개인정보책임자 : 이래관</span>
            <span>사업자등록번호 : 554-88-01130</span> */}
          {/* </div> */}
          <span>
            상호명 코빠밀뉴 대표 : 이래관 &nbsp;&nbsp;|&nbsp;&nbsp;
            개인정보책임자 : 이래관 &nbsp; &nbsp;|&nbsp;&nbsp; 사업자등록번호 :
            554-88-01130
          </span>
          <br />
          <span>
            통신판매업신고번호 : &nbsp;&nbsp;&nbsp;제2019-서울강서-0057호
            서울특별시 강남구 삼성1동 테헤란로 501 FASTFIVE 3층
          </span>
          <br />
          <span>
            고객지원 : &nbsp;&nbsp;&nbsp;문의 게시판 또는 02-6735-1007 (운영시간
            10:00 ~ 17:00 점심: 12:00-13:00, 주말/공휴일은 제외)
          </span>
        </p>
        <div>
          <span>© 2019 COPAMILNEW CORP.</span>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
